import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdAdd, MdProductCatalogingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./ProductFilter/Filter";
import ReactPaginate from 'react-paginate';
import { ProductCatalogStore, useProductCatalogStore, ProductCatalogStoreContext } from "./Store/ProductCatalogStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";
import Moment from "react-moment";
import dem05 from "../assets/images/2.png"



const ProductCatalogList = () => {
    const store = new ProductCatalogStore({
        isloading: true,
        editloading: false
    });
    return (
        <ProductCatalogStoreContext.Provider value={store}>
            <Screen />
        </ProductCatalogStoreContext.Provider>
    )
}
export default ProductCatalogList;


const Screen = observer(() => {
    const {
        ProductCatalog,
        loadcity,
        nolist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        ProductCatalogLoader,
        // setallProductCatalog,
        // allProductCatalog,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getProductCatalogList,
        getFilterProductCatalog,
        TotalPages,
        setProductCatalogpopup,
        ProductCatalogpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        getToken
    } = useProductCatalogStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allProductCatalog, setallProductCatalog] = useState([])
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const [Index, setIndex] = useState("");

    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected + 1);
        getFilterProductCatalog();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const handleImageError = (event) => {
        // This function will be called when the image fails to load
        event.target.src = dem05; // Replace with the path to your default image
    };
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const ProductCatalogs = ProductCatalog?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isatend, setisatend] = useState(false);
    const [isAtBeginning, setisAtBeginning] = useState(false);
    const [Imagelength, setImagelength] = useState("");
    const [Imagearray, setImagearray] = useState([]);




    const prevSlide = (image) => {
        setCurrentSlide((prev) => (prev === 0 ? image.length - 1 : prev - 1));
        setisAtBeginning(currentSlide === 0)

    };

    const nextSlide = (image) => {
        setCurrentSlide((prev) => (prev === image.length - 1 ? 0 : prev + 1));
        setisatend(currentSlide === image.length - 1)
    };

    // Update isAtBeginning and isAtEnd when the currentSlide changes
    React.useEffect(() => {
        setisAtBeginning(currentSlide === 0);
        setisatend(currentSlide === Imagelength.length - 1);
    }, [currentSlide, Imagelength.length]);

    const openLightbox = (index) => {
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const checkAllCheckboxes = () => {
        setallProductCatalog([]);
        // Get all checkboxes with the same name attribute
        var checkboxes = document.getElementsByName('myCheckbox');

        if (checkeddd) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            setallProductCatalog([]);
        } else {
            var emails = [];
            var commaSeparatedEmails = [];
            // var emailArray = emailString.split(",").map((email) => email.trim());
            // Loop through each checkbox and check it
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                var emailValues = checkboxes[i].value.split(',');
                if (emailValues.length > 1) {
                    commaSeparatedEmails.push(...emailValues);
                } else {
                    var emailValue = emailValues[0].trim();
                    if (emailValue !== '' && !emails.includes(emailValue)) {
                        emails.push(emailValue);
                    }

                }
            }
            setallProductCatalog((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
        }

    }

    const isallcheck = (e) => {
        var checkboxes = document.getElementsByName('myCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            var updatedEmails = Array.from(checkboxes)
                .filter((checkbox) => checkbox.checked)
                .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
            setallProductCatalog(updatedEmails);
            if (!checkboxes[i].checked) {
                setchecked(false)
                return
            }
            else {
                setchecked(true)
            }

        }
    }

    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Product Catalog Management</p>
                    <p className=" opacity-80 text-sm">Home / Product Catalog </p>
                </div>
                <div>
                    <Link
                        to="./addproductcatalog"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        <MdAdd />
                        Add ProductCatalog
                    </Link>
                </div>
            </div>
            <Filter />


            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Images</th>
                                <th>Name</th>
                                {/* <th>User</th> */}
                                <th>Created On</th>
                                <th>Approved</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {ProductCatalogLoader &&
                                <>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                (ProductCatalogs.length === 0 ? ProductCatalog : ProductCatalogs)?.map((data, index) => (
                                    <tr key={index} className="capitalize">
                                        <td>                                    {currentPage * ITEMS_PER_PAGE + index+1}
                                        </td>
                                        <td
                                            onClick={() => { setImagearray(data.image_data); openLightbox() }}

                                        >
                                            <label
                                                htmlFor="imagecarousel"
                                                className=" flex space-x-[-5px] cursor-pointer"
                                                title="Delete"

                                            >
                                                {

                                                    data?.image_data.length ?

                                                        data?.image_data.length > 3 ?
                                                            <>

                                                                <img

                                                                    onError={handleImageError}
                                                                    alt="ProductCatalog Image" className="w-[35px] bg-white transition duration-300 hover:opacity-70 rounded-full border-[2px] p-1 border-[#d7d7d7] cursor-pointer h-[35px]" crossOrigin="anonymous" src={BASE_URL + `/uploads/product/${data?.image_data[0]}`} />
                                                                <img

                                                                    onError={handleImageError}
                                                                    alt="ProductCatalog Image" className="w-[35px] bg-white transition duration-300 hover:opacity-70 rounded-full border-[2px] p-1 border-[#d7d7d7] cursor-pointer h-[35px]" crossOrigin="anonymous" src={BASE_URL + `/uploads/product/${data?.image_data[1]}`} />
                                                                <img

                                                                    onError={handleImageError}
                                                                    alt="ProductCatalog Image" className="w-[35px] bg-white transition duration-300 hover:opacity-70 rounded-full border-[2px] p-1 border-[#d7d7d7] cursor-pointer h-[35px]" crossOrigin="anonymous" src={BASE_URL + `/uploads/product/${data?.image_data[2]}`} />

                                                            </>

                                                            :




                                                            data.image_data.map((images, index) => (
                                                                <>

                                                                    <img

                                                                        onError={handleImageError}
                                                                        alt="ProductCatalog Image" className="w-[35px] bg-white transition duration-300 hover:opacity-70 rounded-full border-[2px] p-1 border-[#d7d7d7] cursor-pointer h-[35px]" crossOrigin="anonymous" src={BASE_URL + `/uploads/product/${images}`} />

                                                                    {console.log(BASE_URL + `/uploads/product/${images}`)}
                                                                </>
                                                            ))
                                                        :
                                                        "No Image"

                                                }
                                                {data.image_data.length > 3 &&

                                                    <div className="w-[35px]  flex justify-center items-center rounded-full border-[2px] p-1 border-[#d7d7d7] bg-white cursor-pointer h-[35px]">
                                                        +3
                                                    </div>
                                                }




                                            </label>




                                        </td>
                                        <td>
                                            {data?.product_name}
                                        </td>
                                        {/* <td>
                                            Demo name
                                        </td> */}
                                        <td>
                                            <Moment
                                                format={
                                                    "DD/MM/YYYY"
                                                }
                                            >
                                                {data?.created_at}
                                            </Moment>
                                        </td>

                                        <td>
                                            <label
                                                title="Change Status"
                                                htmlFor="my-modal-5"
                                                className={`btn capitalize Active" ${data?.is_verified === 1 ? "bg-[#1dc9b7] hover:bg-[#1dc9b7]" : "bg-error hover:bg-error"}  text-[#000] border-hidden  btn-xs`}

                                            // onClick={(e) => {
                                            //     getid(data?.id);
                                            //     setProductCatalogpopup(true);
                                            //     getstatus(data?.is_active === 1 ? 0 : 1);
                                            // }}
                                            >
                                                {data?.is_verified === 1 ? "Approved" : "Disapproved"}
                                            </label>
                                        </td>





                                        <th >
                                            <div className="flex space-x-3">
                                                {!admin_permissions.products === 1 ?
                                                    <label
                                                        title="Change Status"
                                                        htmlFor="my-modal-5"
                                                        className={`btn capitalize Active" ${data?.is_active === 1 ? "bg-[#5dff5d] hover:bg-[#5dff5d]" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                                                        onClick={(e) => {
                                                            getid(data?.id);
                                                            setProductCatalogpopup(true);
                                                            getstatus(data?.is_active === 1 ? 0 : 1);
                                                        }}
                                                    >
                                                        {data?.is_active === 1 ? "Active" : "Inactive"}
                                                    </label>
                                                    :
                                                    <label
                                                        title="Change Status"
                                                        htmlFor="my-modal-5"
                                                        className={`btn capitalize Active" ${data?.is_active === 1 ? "bg-[#5dff5d] hover:bg-[#5dff5d]" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}

                                                        onClick={(e) => {
                                                            toast.warning("You have no permissions to edit.")
                                                        }}
                                                    >
                                                        {data?.is_active === 1 ? "Active" : "Inactive"}
                                                    </label>
                                                }
                                            </div>

                                        </th>
                                        <th >
                                            <div className="flex space-x-3">

                                                <div className="flex items-center space-x-3">
                                                    {admin_permissions.products === 1 ?
                                                        <label
                                                            htmlFor="deletecity"
                                                            className=" text-[#d02c2c] cursor-pointer"
                                                            title="Delete"
                                                            onClick={() => { setdeletepopup(true); getid(data?.id) }}
                                                        >
                                                            <MdOutlineDelete />
                                                        </label>
                                                        :
                                                        <label
                                                            htmlFor="deletecity"
                                                            className=" text-[#d02c2c] cursor-pointer"
                                                            title="Delete"
                                                            onClick={() => { toast.warning("You have no permissions to edit.") }}
                                                        >
                                                            <MdOutlineDelete />
                                                        </label>
                                                    }
                                                    {/* {admin_permissions.ProductCatalog === 1 ? */}
                                                    <button title="Edit">
                                                        <Link to={`/productcatalog/${data?.id}`}>
                                                            <RxPencil1 />
                                                        </Link>
                                                    </button>
                                                    {/* :
                                                        <button onClick={() => toast.warning("You have no permissions to edit.")} title="Edit">

                                                            <RxPencil1 />

                                                        </button>
                                                    } */}

                                                </div>
                                            </div>

                                        </th>

                                    </tr>
                                ))
                            }

                            {nolist &&
                                <tr>
                                    <td colSpan={5} className="my-10 text-center">
                                        <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No ProductCatalog Found</p>
                                        <Link
                                            to="./addProductCatalogmanagement"
                                            className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            <MdAdd />
                                            Add ProductCatalog
                                        </Link>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    />
                    {/* } */}

                    {/* status change model start */}
                    {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
                    {/* status change model end */}
                    {console.log("deleteeeeeeeeeeeeeeeeeeee", deletepopup)}
                    {deletepopup &&
                        <>
                            <input type="checkbox" id="deletecity" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this Section
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deletefunction()}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletepopup(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }

                    {lightboxIsOpen &&
                        <>
                            <input type="checkbox" id="imagecarousel" className="modal-toggle" />
                            <div className="modal w-full">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setCurrentSlide(0); setLightboxIsOpen(false) }}
                                    >
                                        ✕
                                    </label>
                                    <div className="modal-action justify-center">
                                        {Imagearray.length > 0 && (
                                            <div className="carousel w-full">
                                                {Imagearray.map((image, i) => (
                                                    <div
                                                        key={i}
                                                        id={`slide${i}`}
                                                        className={`carousel-item relative w-full ${i === currentSlide ? 'visible' : 'hidden'}`}
                                                    >
                                                        <img
                                                            onError={handleImageError}
                                                            crossOrigin="anonymous"  src={BASE_URL + `/uploads/product/${image}`} className="w-[80%]  ml-[10%]  h-[95%]" />
                                                    </div>
                                                ))}
                                                {Imagearray.length > 1 &&
                                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">

                                                        <button disabled={isAtBeginning} onClick={() => { prevSlide(Imagearray); setImagelength(Imagearray); }} className={`btn btn-circle`}>
                                                            ❮
                                                        </button>

                                                        <button disabled={isatend} onClick={() => { nextSlide(Imagearray); setImagelength(Imagearray); }} className={`btn btn-circle`}>
                                                            ❯
                                                        </button>



                                                    </div>
                                                }

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>

                    }

                    {ProductCatalogpopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => setProductCatalogpopup(false)}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to change Status ?
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {!ProductCatalogLoader ? <label

                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => statusChange()}
                                        >
                                            Yes
                                        </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}

                                        <label
                                            onClick={() => setProductCatalogpopup(false)}
                                            // htmlFor="voidstatus"
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </>

                    }
                </div>
            </div>
        </>
    );
});

