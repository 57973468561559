import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class ProductTagStore {

    Token = null;
    ProductTag = [];
    ProductTagLoader=false;
    ProductTagMapList=[];
    savedfun= false;
    DuplicateProduct = "";
    Productlist = [];
    ProductCatalog = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadProductTag = "";
    LoadProductTag = false;
    delateLoader = false;
    nolist = false;
    ProductTagpopup = false;
    deletepopup = false;
    ProductTagname = "";
    status = "1";
    statesid = "";
    ProductTagid = [];
    seoUrl = "";
    statuspopup = false;
    addProductTagname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allProductTag = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    ProductTagFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addProductTagname: observable,
            status: observable,
            errorMessages: observable,
            setaddProductTagname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterProductTag();

            //   this.getStateList();
            return
        }
        if (isloading.Productlist) {
            this.getProductTagList();
            this.getProductTagMapList();
            this.getProductCatalogList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.ProductTageditloading) {
            this.getProductTagform(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterProductTag();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.ProductTagid.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallProductTag = (text) => {
        this.allProductTag = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getProductTagname = (text) => {
        this.ProductTagname = text;
    };

    setaddProductTagname = (text) => {
        this.addProductTagname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setProductTagpopup = (e) => {
        this.ProductTagpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the ProductTag", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setDuplicateProduct = (text) => {
        console.log(text);
        this.DuplicateProduct = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setProductTagFormDetails = (ProductTagDetail) => {
        this.ProductTagFormDetails = {
            ...this.ProductTagFormDetails,
            name: ProductTagDetail.name || "", // Use default value if name is undefined
            is_active: ProductTagDetail.is_active
        };
    };
    // setProductTagFormDetails = (text) => {
    //     this.ProductTagFormDetails = text;
    //     console.log("setProductTagFormDetails", this.ProductTagFormDetails.ProductTagname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get ProductTag list
    getProductTagList = () => {
        runInAction(() => {
            this.ProductProductTagLoader = true;
            this.errLoadProductProductTag = "";
            this.saved = false;
            this.ProductProductTag = [];
        });
        getMethodData(`/api/v1/admin/tags/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductProductTagLoader = false;
                        this.errLoadProductProductTag = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductProductTagLoader = false;
                        this.errLoadProductProductTag = resp?.data?.message;
                  
                        this.ProductTag = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductProductTag = false;
                    this.errLoadProductProductTag = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductProductTagLoader = false;
                    if (!error.response) {
                        this.errLoadProductProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductProductTag = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
 
    //Filter city
    // filter League court

    getFilterProductTag = () => {
        runInAction(() => {
            this.ProductTagLoader = true;
            this.LoadProductTag = true;
            this.errLoadProductTag = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/products/search`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.ProductTagLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.ProductTagLoader = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductTag = resp?.data?.data;
                        this.getProductCatalogList();
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.ProductTagLoader = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Product list 
    // get ProductTag list
    getProductList = (id) => {
        runInAction(() => {
            this.ProductTagLoader = true;
            this.errLoadProductTag = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/ProductTags/get-list-mapping`,

            {
                "ProductTag_id": id
            }
            , this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductTagLoader = false;
                        this.errLoadProductTag = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    console.log(resp?.data?.data.length);
                    runInAction(() => {
                        this.ProductTagLoader = false;
                        this.errLoadProductTag = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                            console.log("nolist === true");
                        } else {
                            console.log("nolist === false");
                            this.nolist = false;
                        }
                        this.Productlist = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductTag = false;
                    this.errLoadProductTag = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductTagLoader = false;
                    if (!error.response) {
                        this.errLoadProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductTag = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getProductCatalogList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.ProductCatalog = [];
        });
        getMethodData(`/api/v1/admin/products/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                  
                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
  

    // add new ProductTag
    addProductTag = () => {
        runInAction(() => {
            this.addloadProductTag = true;
            this.errLoadProductTag = "";
        });
        const formData = new FormData();

        formData.append('ProductTag_name', this.addProductTagname);
        formData.append('status', this.status);



        postMethodData("/api/v1/admin/ProductTags/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadProductTag = false;
                    this.errLoadProductTag = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadProductTag = false;
                    this.errLoadProductTag = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getProductTagMapList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadProductTag = false;
                this.errLoadProductTag = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadProductTag = false;
                    if (!error.response) {
                        this.errLoadProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductTag = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.ProductTagLoader = true;
            this.ProductTagpopup = true;

            this.errLoadProductTag = "";
        });
        postMethodData(`/api/v1/admin/ProductTags/update-status`, {

            "record_id": this.ProductTagid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductTagid = [];
                        this.ProductTagLoader = false;
                        this.ProductTagpopup = false;
                        this.errLoadProductTag = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductTagLoader = false;
                        this.ProductTagpopup = false;
                        this.errLoadProductTag = resp?.data?.message;
                        this.getFilterProductTag();
                        this.ProductTagid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductTagLoader = false;
                    this.ProductTagpopup = false;

                    this.errLoadProductTag = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductTagLoader = false;
                        this.ProductTagpopup = false;
                        this.ProductTagid = [];
                        this.errLoadProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductTagLoader = false;
                    this.ProductTagpopup = false;
                    this.ProductTagid = [];


                    this.errLoadProductTag = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    // BulkstatusChange = (allProductTag) => {
    //     runInAction(() => {
    //         this.ProductTagLoader = true;
    //         this.ProductTagpopup = true;

    //         this.errLoadProductTag = "";
    //     });

    //     postMethodData(`/api/v1/admin/ProductTag/toggle-bulk-status`, {

    //         "branchId": JSON.stringify(allProductTag),
    //         "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.ProductTagLoader = false;
    //                     this.ProductTagpopup = false;
    //                     this.errLoadProductTag = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.ProductTagLoader = false;
    //                     this.ProductTagpopup = false;

    //                     this.errLoadProductTag = resp?.data?.message;
    //                     this.getFilterProductTag();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.ProductTagLoader = false;
    //                 this.ProductTagpopup = false;

    //                 this.errLoadProductTag = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.ProductTagLoader = false;
    //                     this.ProductTagpopup = false;

    //                     this.errLoadProductTag = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.ProductTagLoader = false;
    //                 this.ProductTagpopup = false;

    //                 this.errLoadProductTag = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };

    //Delete method
    deletefunction = (option_id , tag_id) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductTag = "";
        });
        postMethodData(`/api/v1/admin/products/delete-prod-tag`, {

            "product_id": option_id,
            "tag_id": tag_id,


        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductTag = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductTag = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductTag = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadProductTag = resp?.data?.message;
                    this.getProductTagMapList();
                    this.ProductTagid = [];

                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductTag = false;
                this.errLoadProductTag = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductTag = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    this.setdelateLoader(false);
                    this.ProductTagid = [];

                    this.LoadProductTag = false;
                    this.errLoadProductTag = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // // Bulk Delete 
    // BulkDelete = (allProductTag) => {
    //     runInAction(() => {
    //         this.ProductTagLoader = true;
    //         this.ProductTagpopup = true;

    //         this.errLoadProductTag = "";
    //     });

    //     postMethodData(`/api/v1/admin/ProductTag/multi-delete`, {

    //         "ProductTagId": JSON.stringify(allProductTag),
    //         // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.ProductTagLoader = false;
    //                     this.ProductTagpopup = false;
    //                     this.errLoadProductTag = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.ProductTagLoader = false;
    //                     this.ProductTagpopup = false;

    //                     this.errLoadProductTag = resp?.data?.message;
    //                     this.getFilterProductTag();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.ProductTagLoader = false;
    //                 this.ProductTagpopup = false;

    //                 this.errLoadProductTag = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.ProductTagLoader = false;
    //                     this.ProductTagpopup = false;

    //                     this.errLoadProductTag = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.ProductTagLoader = false;
    //                 this.ProductTagpopup = false;

    //                 this.errLoadProductTag = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };


    //get ProductTag by id
    getProductTagform = (id) => {
        runInAction(() => {
            this.ProductTagLoader = true;
            this.ProductTagpopup = true;

            this.errLoadProductTag = "";
        });
        postMethodData(`/api/v1/admin/ProductTags/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductTagLoader = false;
                        this.ProductTagpopup = false;
                        this.errLoadProductTag = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setProductTagFormDetails(resp.data.data.ProductTagDetail);
                        this.ProductTagLoader = false;
                        this.ProductTagpopup = false;
                        this.errLoadProductTag = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductTagLoader = false;
                    this.ProductTagpopup = false;

                    this.errLoadProductTag = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductTagLoader = false;
                        this.ProductTagpopup = false;

                        this.errLoadProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductTagLoader = false;
                    this.ProductTagpopup = false;

                    this.errLoadProductTag = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateProductTag = (id) => {
        runInAction(() => {
            this.addloadProductTag = true;
            this.errLoadProductTag = "";
        });
        const formData = new FormData();


        formData.append('record_id', id);
        formData.append('ProductTag_name', this.ProductTagFormDetails.name);
        formData.append('is_active', this.ProductTagFormDetails.is_active);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/ProductTags/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getProductTagList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };


    // add new ProductTag
    addProductProductTag = (ProductTag_id) => {
        return new Promise((resolve, reject) => {
            runInAction(() => {
                this.ProductTagLoader = true;
                this.savedfun = false;
                this.Productlist = [];
                this.errLoadProductTag = "";
            });
            
            const formData = new FormData();
    
            formData.append('tag_name', ProductTag_id);
            formData.append('status', 1);
    
            postMethodData("/api/v1/admin/tags/add", formData, this.Token)
                .then((resp) => {
                    this.savedfun = true;
    
                    if (resp?.data?.status === false) {
                        runInAction(() => {
                            this.ProductTagLoader = false;
                            this.Productlist = [];
                            this.errLoadProductTag = resp?.data?.message;
                            toast.error(resp.data.message);
                            reject(new Error(resp?.data?.message));
                        });
                    } else if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.ProductTagLoader = false;
                            this.errLoadProductTag = resp?.data?.message;
                            toast.success(resp.data.message);
                            this.getProductTagList()
                            this.savedfun = true;
                            resolve();
                        });
                    } else {
                        runInAction(() => {
                            this.ProductTagLoader = false;
                            this.errLoadProductTag = resp?.data?.message;
                            reject(new Error(resp?.data?.message));
                        });
                    }
                })
                .catch((error) => {
                    runInAction(() => {
                        this.ProductTagLoader = false;
                        this.getProductList(ProductTag_id);
                        this.savedfun = true;
    
                        if (!error.response) {
                            this.errLoadProductTag = error?.message;
                            toast.error(error?.message);
                            this.setDuplicateProduct(false);
                            reject(error);
                        } else {
                            this.errLoadProductTag = error?.response?.data?.message;
                            this.setDuplicateProduct(false);
                            toast.error(error?.response?.data?.message);
                            reject(new Error(error?.response?.data?.message));
                        }
                    });
                });
        });
    };

    //product tag mapping 
    ProductTagMapping = (ProductTag_id, tag_id) => {
        return new Promise((resolve, reject) => {
            runInAction(() => {
                this.ProductTagLoader = true;
                this.savedfun = false;
                this.Productlist = [];
                this.errLoadProductTag = "";
            });
            
            const formData = new FormData();
    
            formData.append('product_id', ProductTag_id);
            formData.append('tag_id', tag_id);
    
            postMethodData("/api/v1/admin/products/add_prod_tag", formData, this.Token)
                .then((resp) => {
                    this.savedfun = true;
    
                    if (resp?.data?.status === false) {
                        runInAction(() => {
                            this.ProductTagLoader = false;
                            this.Productlist = [];
                            this.errLoadProductTag = resp?.data?.message;
                            toast.error(resp.data.message);
                            reject(new Error(resp?.data?.message));
                        });
                    } else if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.ProductTagLoader = false;
                            this.errLoadProductTag = resp?.data?.message;
                            toast.success(resp.data.message);
                            this.getProductTagList()
                            this.savedfun = true;
                            resolve();
                        });
                    } else {
                        runInAction(() => {
                            this.ProductTagLoader = false;
                            this.errLoadProductTag = resp?.data?.message;
                            reject(new Error(resp?.data?.message));
                        });
                    }
                })
                .catch((error) => {
                    runInAction(() => {
                        this.ProductTagLoader = false;
                        this.getProductList(ProductTag_id);
                        this.savedfun = true;
    
                        if (!error.response) {
                            this.errLoadProductTag = error?.message;
                            toast.error(error?.message);
                            this.setDuplicateProduct(false);
                            reject(error);
                        } else {
                            this.errLoadProductTag = error?.response?.data?.message;
                            this.setDuplicateProduct(false);
                            toast.error(error?.response?.data?.message);
                            reject(new Error(error?.response?.data?.message));
                        }
                    });
                });
        });
    };

    getProductTagMapList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.ProductTagMapList = [];
        });
        getMethodData(`/api/v1/admin/products/prod_tag_mapping`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                  
                        this.ProductTagMapList = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    deleteProductProductTag = (recordid, ProductTagid) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.ProductTagLoader = true;
            this.Productlist = [];

            this.errLoadProductTag = "";
        });
        postMethodData(`/api/v1/admin/ProductTags/delete-ProductTag-product`, {

            "record_id": JSON.parse(recordid),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductTag = false;
                    this.setdelateLoader(true);
                    this.ProductTagLoader = true;
                    this.Productlist = [];
                    this.errLoadProductTag = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductTag = false;
                    this.setdelateLoader(false);
                    this.ProductTagLoader = false;
                    this.errLoadProductTag = resp?.data?.message;
                    this.getProductList(ProductTagid);

                    this.ProductTagid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductTag = false;
                this.errLoadProductTag = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductTag = false;
                        this.ProductTagLoader = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductTag = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductTag = false;
                    this.errLoadProductTag = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
}




export const ProductTagStoreContext = createContext(new ProductTagStore());
export const useProductTagStore = () => useContext(ProductTagStoreContext);
