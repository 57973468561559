import React, { useEffect, useState } from 'react'
import { Input, Textarea } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useCouponStore, CouponStore, CouponStoreContext } from "../Store/CouponStore";
import { useAuthStore } from '../../Auth/store/Auth';

const EditCoupenManagement = observer(() => {
  const { id } = useParams();
  return (
    <CouponStoreContext.Provider
      value={
        new CouponStore({
          loadeditcitybol: false,
          editloading: true,
          id: id,
        })
      }
    >
      <Screen />
    </CouponStoreContext.Provider>


  )

});
export default EditCoupenManagement;

const Screen = observer(() => {
  const {
    getname,
    getcode,
    getdiscount,
    getdiscount_type,
    getstartdate,
    getenddate,
    getstatus,
    updatedcoupon,
    setmax_offer_amt,
    setmin_eligible_amt,
    setper_person_limit,
    settotal_use_limit,
    showenddate,
    total_use_limit,
    per_person_limit,
    max_offer_amt,
    min_eligible_amt,
    name,
    code,
    discount,
    discount_type,
    status,
    saved,
    startdate,
    enddate,
    loadeditcoupon,
    getToken
  } = useCouponStore();
  const { adminAuthApproved } = useAuthStore();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }

  }, [adminAuthApproved]);
  const [startdatestate, setstartdatestate] = useState(null)
  useEffect(() => {
    if (saved) {
      navigate("../coupenmanagement");
    }
    if (startdatestate === null ? true : false) {

    }
  }, [saved, startdatestate, startdate]);
  const handleDiscountChange = (e) => {
    const inputValue = e.target.value.replace(/\./g, '');
    if (discount_type == "percentage") {
      if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 100) {
        // Update the state only if the input is a valid number within the desired range
        getdiscount(inputValue?.toString());
      }
    }
    else {
      if (!isNaN(inputValue) && inputValue >= 0) {
        // Update the state only if the input is a valid number within the desired range
        getdiscount(inputValue);

      }
    }

  };
  return (
    <>
      <div className='flex justify-between'>
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit Promo Code</p>
          <p className=" opacity-80 text-sm">Home / Promo Code / Edit</p>
        </div>
        <div>
          <Link to="../coupenmanagement" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form onSubmit={(e) => { updatedcoupon(id); e.preventDefault() }}>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">


            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Promo Code Name<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Coupon Name"
                required
                disabled
                placeholder="Promo Code Name"
                onChange={(e) => getname(e.target.value)}
                value={name}
                id="Coupon Name"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
              <Textarea
                name="Description"
                required
                disabled
                placeholder="description....."
                value={code}
                onChange={(e) => getcode(e.target.value)}
                id="Description"
                type="text"
                rows={1}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount Type<span className="text-[#f00]">&nbsp;*</span></label>
              <select
                name="Discount Type"
                required
                disabled
                onClick={() =>
                  getdiscount("")
                }
                onChange={(e) => getdiscount_type(e.target.value)}
                value={discount_type}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value={'amount'}>Amount</option>
                <option value={'percent'}>Percentage</option>
              </select>
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Discount"
                disabled
                required
                placeholder="Discount"
                onChange={handleDiscountChange}
                value={discount}
                id="Discount"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Total Use Limit<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="limit"
                required
                placeholder="Total Use Limit"
                value={total_use_limit}
                onChange={(e) => settotal_use_limit(e.target.value)}
                id="limit"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Total Person Limit<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="limit"
                required
                placeholder="Total Person Limit"
                value={per_person_limit}
                onChange={(e) => setper_person_limit(e.target.value)}
                id="limit"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Maximum Amount<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Maximum"
                required
                placeholder="value"
                value={max_offer_amt}
                disabled
                onChange={(e) => setmax_offer_amt(e.target.value)}
                id="Maximum"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Minimum Amount<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Minimum"
                required
                placeholder="value"
                disabled
                value={min_eligible_amt}
                onChange={(e) => setmin_eligible_amt(e.target.value)}
                id="Minimum"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="startdate"
                disabled
                placeholder=""
                value={startdate}
                onChange={(e) => { getstartdate(e.target.value); }}
                // max="2025-01-01"
                id="startdate"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                End Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="enddate"
                placeholder=""
                onChange={(e) => getenddate(e.target.value)}
                // defaultValue={<Moment format="YYYY-MM-DD">{{updatedEndDate}}</Moment>}
                value={enddate}
                // disabled={startdate === "" ? true : false}
                // min={new Date(startdate ? startdate : null).toISOString().split('T')[0]}
                // max="2025-01-01"
                id="enddate"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>


            <div>
              <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
              <select
                name="Status"
                required
                onChange={(e) => getstatus(e.target.value)}
                value={status}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../coupenmanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!loadeditcoupon ?
              <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                <MdOutlineCheck className="mr-1" />
                Update
              </button>
              :
              <button
                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                type="button"
              //  onClick={()=> changeStep("4")}
              >
                <svg
                  className="animate-spin mr-1 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading..
              </button>
            }

          </div>
        </form>
      </div>
    </>
  )
});
