import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from 'date-fns';


export class CouponStore {

  Token = null;
  loadcouponlist = false;
  deletepopup = false;
  delateLoader=false;
  total_use_limit = "";
  per_person_limit = "";
  max_offer_amt = null;
  min_eligible_amt = "";
  user_id = [];
  Coupenpopup = false;
  CoupenLoader = false;
  name = "";
  code = "";
  saved = false;
  startdate = "";
  enddate = "";
  showenddate = "";
  showstartdate = "";
  discount = "";
  discount_type = "";
  status = "";
  coupon = [];
  errloadcoupon = "";
  nolist = false;
  loadCoupon = false;
  coupon_id = [];

  loadeditcoupon = false;
  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getCoupon();
      return
    }
    if (isloading.editloading) {
      this.getcouponbyid(isloading.id);
      return
    }
  }

  getToken = (e) => {
    this.Token = e;
  }

  //   // get Coupen id
  getcouponid = (text) => {
    this.coupon_id.push(text);
  }
  setcoupenpopup = (e) => {
    this.Coupenpopup = e;
  };
  setdeletepopup = (e) => {
    this.deletepopup = e;
  };
  //   // get home name
  //   getHomename = (text) => {
  //     this.homename = text;
  //   }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };
  // get updated status 
  getupdatedstatus = (text) => {
    this.updatedStatus = text;
  }
  //get year
  getyear = (text) => {
    this.year = text;
  }
  //get name
  getname = (text) => {
    this.name = text;
  };
  //get code 
  getcode = (text) => {
    this.code = text;
  }
  //get discount
  getdiscount = (text) => {
    this.discount = text;

  }
  getdiscount_type = (text) => {
    this.discount_type = text;
  }
  //get start date
  getstartdate = (text) => {
    const timestamp = new Date(text).getTime();
    this.showstartdate = timestamp
    this.startdate = timestamp;
  };
  //get end date
  getenddate = (text) => {
    const timestamp = new Date(text).getTime();
    this.showenddate = timestamp
    this.enddate = text;
  };
  //edit start date
  editstartdate = (text) => {
    this.updatedStartDate = text
  }
  //edit end date
  editenddate = (text) => {
    this.updatedEndDate = text
  }

  //edit season name 
  editseasonname = (text) => {
    this.editname = text
  }
  //edit year 
  edityears = (text) => {
    this.edityear = text;
  }
  editseasonstatus = (text) => {
    this.editstatus = text
  }
  getid = (text) => {
    this.user_id.push(text);
  };

  settotal_use_limit = (text) => {
    this.total_use_limit = text
  }
  setper_person_limit = (text) => {
    this.per_person_limit = text
  }
  setmax_offer_amt = (text) => {
    this.max_offer_amt = text
  }
  setmin_eligible_amt = (text) => {
    this.min_eligible_amt = text
  }

  setdelateLoader = (e) => {
    this.delateLoader = e;
  }
  //   //get zipcode
  //   getzipcode = (text) => {
  //     this.zipcode = text;
  //   };

  //   // get country name
  //   getcountryname = (text) => {
  //     this.country = text;
  //   };

  // get Coupen list
  getCoupon = () => {
    runInAction(() => {
      this.loadCoupon = true;
      this.nolist = false;
      this.errloadcoupon = "";
      this.saved = false;
      this.coupon = [];
    });
    getMethodData("/api/v1/admin/promo-code/get-list", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.coupon = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addseasoncourt = false;
          this.loadSeason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  //Delete method
  deletefunction = () => {
    runInAction(() => {
      this.setdelateLoader(true);
      this.deletepopup = true;
      this.errLoadBanner = "";
    });
    postMethodData(`/api/v1/admin/promo-code/delete`, {

      "record_id": this.user_id,

    }, this.Token).then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.LoadBanner = false;
          this.setdelateLoader(true);
          this.deletepopup = true;
          this.errLoadBanner = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.LoadBanner = false;
          this.setdelateLoader(false);
          this.deletepopup = false;
          this.errLoadBanner = resp?.data?.message;
          this.getCoupon();
          toast.success(resp.data.message);
        })
        return;
      }
      runInAction(() => {
        this.LoadBanner = false;
        this.errLoadBanner = resp?.data?.message;
      });

    })
      .catch((error) => {
        runInAction(() => {
          this.addloadcity = false;
          if (!error.response) {
            this.LoadBanner = false;
            this.setdelateLoader(false);
            this.deletepopup = false;
            this.errLoadBanner = error?.message;
            toast.error(error?.message);
          }
          this.LoadBanner = false;
          this.errLoadBanner = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      })
  }
  // get filter Coupen
  getFilterCoupen = () => {
    runInAction(() => {
      this.loadCoupon = true;
      this.nolist = false;
      this.errloadcoupon = "";
      this.saved = false;
      this.coupon = [];
    });
    getMethodData(`/api/admin/coupon?filters[page]=1&filters[name]=${this.name}&filters[code]=${this.code}&filters[discount_type]=${this.discount_type}&filters[discount]=${this.discount}&filters[from_date]=${this.startdate}&filters[to_date]=${this.enddate}&filters[isActive]=${this.status}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.coupon = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addseasoncourt = false;
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  // add Season
  addCoupon = () => {
    runInAction(() => {
      this.errloadcoupon = "";
    });
    postMethodData("/api/v1/admin/promo-code/add", {
      promo_name: this.name,
      description: this.code,

      promo_type: this.discount_type,
      promo_value: parseFloat(this.discount),
      start_timestamp: this.startdate,
      valid_timestamp: this.enddate,
      status: this.status,
      total_use_limit: this.total_use_limit,
      per_person_limit: this.per_person_limit,
      max_offer_amt: this.max_offer_amt,
      min_eligible_amt: this.min_eligible_amt

    }, this.Token).then((resp) => {

      if (resp?.data?.status === false) {

        runInAction(() => {
          this.errloadcoupon = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;

      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.errloadcoupon = resp?.data?.message;
          toast.success(resp.data.message);
          this.getdiscount("");
          this.getdiscount_type("");
          this.getCoupon();
          this.saved = true;
        })
        return
      }
      runInAction(() => {
        this.addseasoncourt = false;
        this.erraddseasoncourt = resp?.data?.message;
      });

    })
      .catch((error) => {
        runInAction(() => {
          this.addseasoncourt = false;
          if (!error.response) {
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          this.erraddseasoncourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };



  //Active and inactive

  statusChange = (id) => {
    runInAction(() => {
      this.UserLoader = true;
      this.Coupenpopup = true;

      this.errLoadAminUsers = "";
    });
    postMethodData(`/api/v1/admin/promo-code/update-status`, {
      "status": this.status,
      "record_id": this.coupon_id
    }, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.UserLoader = false;
            this.Coupenpopup = false;
            this.errLoadAminUsers = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.UserLoader = false;
            this.Coupenpopup = false;
            this.user_id = [];
            this.errLoadAminUsers = resp?.data?.message;
            this.getCoupon();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.UserLoader = false;
          this.Coupenpopup = false;

          this.errLoadAminUsers = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.UserLoader = false;
            this.Coupenpopup = false;

            this.errLoadAminUsers = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.UserLoader = false;
          this.Coupenpopup = false;

          this.errLoadAminUsers = error?.response?.data?.message;
        })
        return;
      })
  };

  //get Coupon by id
  getcouponbyid = (id) => {
    postMethodData(`/api/v1/admin/promo-code/form`, {

      "record_id": id,
      // "status": JSON.stringify(this.status)

    }, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.errloadcoupon = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadcoupon = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            console.log("resp=============>>>>>>>>>>>>>>>>", resp?.data?.data)
            this.errloadcoupon = resp?.data?.message;
            this.getstartdate(format(new Date(resp?.data?.data[0]?.offer_start_time), 'yyyy-MM-dd'));
            this.startdate = format(new Date(resp?.data?.data[0]?.offer_start_time), 'yyyy-MM-dd');
            this.getenddate(format(new Date(resp?.data?.data[0]?.offer_end_time), 'yyyy-MM-dd'))
            this.discount = resp?.data?.data[0]?.promo_value;
            this.code = resp?.data?.data[0]?.description;
            this.discount_type = resp?.data?.data[0]?.promo_type;
            this.min_eligible_amt = resp?.data?.data[0]?.min_eligible_amt;
            this.max_offer_amt = resp?.data?.data[0]?.max_offer_amt;
            this.per_person_limit = resp?.data?.data[0]?.per_person_limit;
            this.total_use_limit = resp?.data?.data[0]?.total_use_limit;
            this.enddate = format(new Date(resp?.data?.data[0]?.offer_end_time), 'yyyy-MM-dd');
            this.name = resp?.data?.data[0]?.promo_name;
            this.status = resp?.data?.data[0]?.is_active;
          })
          return;
        }
        runInAction(() => {
          this.errloadcoupon = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloadcoupon = error?.response?.data?.message;
        })
        return;
      })
  }


  //update method
  updatedcoupon = (id) => {
    runInAction(() => {
      this.loadeditcoupon = true
      this.errloadcoupon = "";
    });
    postMethodData(`/api/v1/admin/promo-code/edit`,
      {
        promo_name: this.name,
        description: this.code,
        record_id: id,
        promo_type: this.discount_type,
        promo_value: parseFloat(this.discount),
        start_timestamp: this.showstartdate,
        valid_timestamp: this.showenddate,
        status: this.status,
        total_use_limit: this.total_use_limit,
        per_person_limit: this.per_person_limit,
        max_offer_amt: this.max_offer_amt,
        min_eligible_amt: this.min_eligible_amt
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadeditcoupon = false;
            this.errloadcoupon = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadcoupon = false;
            this.loadeditcoupon = false;
            this.erraddcity = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
          })
          return
        }
        runInAction(() => {
          this.addloadcity = false;
          this.loadeditcoupon = false;
          this.erraddcity = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloadcity = false;
            this.loadeditcoupon = false;
            this.erraddcity = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadeditcoupon = false;
          this.errloadcoupon = error?.response?.data?.message;
        })
        return;
      })
  };
}




export const CouponStoreContext = createContext(new CouponStore());
export const useCouponStore = () => useContext(CouponStoreContext);