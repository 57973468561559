import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class ProductCatalogStore {

    Token = null;
    ProductCatalog = [];
    ProductOptions = [];
    productDetail = [];
    shop = [];
    productSpecificationDetail = [];
    productOptionDetail = [];
    optionDetail = [];
    optionslist = [];
    productIdentifier = "";
    prodcutwarranty="";
    YouTubevideo = "";
    model = "";
    warranty_type = "";
    minimumprice = "";
    code_for_all_variant = "";
    defaultImageDetail = [];
    uploaded_file = "";
    brandname = "";
    shop_id = "";
    productName = "";
    product_code = "";
    cod_available = "false";
    product_featured = "false";
    product_approved = "false";
    is_active = "";
    ProductValues = [];
    producttype = ("0");
    productcatgory = "";
    code_for_all_variant = "";
    content = "";
    Brand = [];
    parentId = null;
    productValueid = [];
    All_categories = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadProductCatalog = "";
    LoadProductCatalog = false;
    delateLoader = false;
    nolist = false;
    ProductCatalogpopup = false;
    deletepopup = false;
    ProductCatalogname = "";
    status = "1";
    statesid = "";
    ProductCatalogid = "";
    seoUrl = "";
    statuspopup = false;
    addProductCatalogname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allProductCatalog = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    ProductCatalogFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            // addProductCatalogname: observable,
            status: observable,
            errorMessages: observable,
            setaddProductCatalogname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            // this.getFilterProductCatalog();
            this.getProductCatalogList();

            //   this.getStateList();
            return
        }
        if (isloading.addProductCataloglist) {
            //   this.getStateList();
            this.getAllCategoriesList();
            this.getBrandList();
            this.getshop();
            this.getProductOptionsList();
        }
        if (isloading.editProductCataloglist) {
            this.getProductCatalogform(isloading.id);
            this.getshop();
            this.getProductOptionsList();
            this.getAllCategoriesList();
            this.getBrandList();
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this?.getFilterProductCatalog();
        //   this.status="";
        //   this.statesid="";
    }


    /////////Add Product

    setproducttype = (text) => {
        this.producttype = text
    };

    setproductcatgory = (text) => {
        this.productcatgory = text
    };
    setproductIdentifier = (text) => {
        this.productIdentifier = text
    };
    setproductName = (text) => {
        this.productName = text
    };
    setBrandName = (text) => {
        console.log("setBrandName======================>>>>>>>>>>>>>>>>>>>>>>>>>>", text);

        this.brandname = text
    };
    setshop = (text) => {
        console.log("setBrandName======================>>>>>>>>>>>>>>>>>>>>>>>>>>", text);

        this.shop_id = text
    };

    setCategory = (text) => {
        console.log("setCategory======================>>>>>>>>>>>>>>>>>>>>>>>>>>", text);

        this.Category = text
    };
    setmodel = (text) => {
        this.model = text
    };
    setminimumprice = (text) => {
        this.minimumprice = text
    };
    setprodcutwarranty = (text) => {
        this.prodcutwarranty = text
    };
    setwarranty_type = (text) => {
        this.warranty_type = text
    };
    setYouTubevideo = (text) => {

        this.YouTubevideo = text
    };
    // setDiscription= (text) => {
    //     this.Discription = text
    // };

    getcontent = (text) => {
        this.content = text;
    }


    setcod_available = (text) => {
        this.cod_available = !this.cod_available
    };
    setproduct_featured = (text) => {
        this.product_featured = !this.product_featured
    };
    setproduct_approved = (text) => {
        this.product_approved = !this.product_approved
    };
    setis_active = (text) => {
        this.is_active = !this.is_active
    };
    setproduct_code = (text) => {
        this.product_code = text;
    }

    ////////////////////












    setparentId = (text) => {
        this.parentId = text

    };
    setalldeactivate = (text) => {
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.ProductCatalogid = text;
    };
    setproductValueid = (text) => {
        this.productValueid = text;
    };

    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallProductCatalog = (text) => {
        this.allProductCatalog = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getProductCatalogname = (text) => {
        this.ProductCatalogname = text;
    };

    setaddProductCatalogname = (text) => {
        this.addProductCatalogname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
    };

    setProductCatalogpopup = (e) => {
        this.ProductCatalogpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }

    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {

        this.logoimage = text;
    };
    getbanner_image = (text) => {
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setuploaded_file = (text) => {
        this.uploaded_file = text;
    };

    setcode_for_all_variant = (text) => {
        this.code_for_all_variant = !this.code_for_all_variant
    };


    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setProductCatalogFormDetails = (ProductCatalogDetail) => {
        this.ProductCatalogFormDetails = {
            ...this.ProductCatalogFormDetails,
            name: ProductCatalogDetail.name || "", // Use default value if name is undefined
            seo_url: ProductCatalogDetail.seo_url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.ProductCatalogFormDetails.logo_image) ? this.logofile.length == 0 ? ProductCatalogDetail.logo_image    : this.ProductCatalogFormDetails.logo_image  : this.logofile.length !== 0 ? this.ProductCatalogFormDetails.logo_image :  ProductCatalogDetail.logo_image   || "",
            // banner_image: this.isBase64(ProductCatalogDetail.banner_image) ? this.ProductCatalogFormDetails.banner_image : ProductCatalogDetail.banner_image || "",
            logo_image: ProductCatalogDetail.logo_image || "",
            banner_image: ProductCatalogDetail.banner_image || "",
            is_active: ProductCatalogDetail.is_active
        };
    };
    // setProductCatalogFormDetails = (text) => {
    //     this.ProductCatalogFormDetails = text;
    //     console.log("setProductCatalogFormDetails", this.ProductCatalogFormDetails.ProductCatalogname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }
    getshop = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.shop = [];
        });
        getMethodData(`/api/v1/admin/products/get-shops`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.shop = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    // get ProductCatalog list
    getProductCatalogList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.ProductCatalog = [];
        });
        getMethodData(`/api/v1/admin/products/get-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court
    // get root categories list
    getAllCategoriesList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.All_categories = [];
        });
        getMethodData(`/api/v1/admin/product-categories/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog
                            = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.All_categories = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errLoadProductCatalog
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    // get brand list
    getBrandList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.Brand = [];
        });
        getMethodData(`/api/v1/admin/brand/fetch`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Brand = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadBrand = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getFilterProductCatalog = () => {
        runInAction(() => {
            this.LoadProductCatalog = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.getProductCatalogList = [];
        });
        postMethodData(`/api/v1/admin/products/search`, {
            keyword: this.filterSearch,
            page: this.PaginationPage

        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new ProductCatalog
    addProductCatalog = (product_options, specificatios, selectedValues) => {
        runInAction(() => {
            this.addloadProductCatalog = true;
            this.errLoadProductCatalog = "";
        });
        console.log("videooooooooooooooo", this.YouTubevideo);
        // const formData = new FormData();

        // formData.append('product_files',JSON.stringify(selectedValues) );
        // // formData.append('selectedValues', JSON.stringify(selectedValues.option_id));
        // // formData.append('selectedValues', selectedValues.data); // Assuming data contains the file object

        // formData.append('product_name', this.productName);
        // formData.append('product_seller_id', this.productcatgory);
        // formData.append('identifier', this.productIdentifier);
        // formData.append('code_for_all_variant', this.code_for_all_variant);

        // formData.append('product_code', this.product_code);

        // formData.append('category_id', this.Category);

        // formData.append('brand_id', this.brandname);

        // formData.append('model', this.model);

        // formData.append('min_selling_price', this.minimumprice);

        // formData.append('youtube_video_url', this.YouTubevideo);
        // formData.append('description', this.content);
        // formData.append('cod_available', this.cod_available ? 0 : 1);
        // formData.append('product_featured', this.product_featured ? 0 : 1);
        // formData.append('product_approved', this.product_approved ? 0 : 1);
        // formData.append('product_specification', JSON.stringify(specificatios));
        // formData.append('product_options', JSON.stringify(product_options));
        // // formData.append('product_options', JSON.stringify(product_options));

        // // formData.append('youtube_video_url', this.YouTubevideo);
        console.log(product_options, specificatios, selectedValues);
        if (product_options[0].option_id == "") {
            toast.error("Please Select Atleast One Variants & options ");
            this.addloadProductCatalog = false;

            return;


        }
        else {
            this.addloadProductCatalog = true;

        }
        if (selectedValues.length === 0) {
            toast.error("Atleast Upload One Image ");
            this.addloadProductCatalog = false;

            return;


        }
        else {
            this.addloadProductCatalog = true;

        }
        if (!this.YouTubevideo || !this.YouTubevideo.startsWith("https://")) {
            toast.error("Please provide a valid URL starting with 'https://'");
            this.addloadProductCatalog = false;
            return;
        }
        else {
            this.addloadProductCatalog = true;

        }
        postMethodData("/api/v1/admin/products/add", {

            product_files: selectedValues,
            product_name: this.productName,
            product_seller_id: 1,
            identifier: this.productIdentifier || null,
            code_for_all_variant: this.code_for_all_variant,
            product_code: this.product_code,
            category_id: this.Category,
            warranty_type: this.warranty_type || "days",
            warranty: this.prodcutwarranty,
            brand_id: this.brandname,
            product_seller_id : this.shop_id,
            model: this.model,
            min_selling_price: this.minimumprice,
            youtube_video_url: this.YouTubevideo,
            description: this.content == "" ? "Demo" : this.content,
            cod_available: this.cod_available ? 0 : 1,
            product_featured: this.product_featured ? 0 : 1,
            product_approved: this.product_approved ? 0 : 1,
            product_specification: specificatios,
            product_options: product_options

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp?.data?.formErrors?.variantImage || resp?.data?.formErrors?.defaultImage || resp?.data?.message);

                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getProductCatalogList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);

                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.formErrors?.variantImage || error?.response?.data?.formErrors?.defaultImage || error?.response?.data?.message);
                })
                return;
            });
    };

    UpdateProductCatalog = (product_options, specificatios, selectedValues, id) => {
        runInAction(() => {
            this.addloadProductCatalog = true;
            this.errLoadProductCatalog = "";
        });
    
        // Check if any object in selectedValues has the key "check" with value "default"
        const hasDefaultCheck = selectedValues.some(value => value.check === "default");
        const strippedContent = this.content.replace(/<[^>]*>/g, '');
    
        // Filter out the object with "default" check value
        const filteredSelectedValues = selectedValues.filter(value => value.check !== "default");
    
        // Construct the request object based on the presence of the "default" check
        const requestObject = {
            product_name: this.productName,
            product_seller_id: 1,
            record_id: id,
            identifier: this.productIdentifier || null,
            code_for_all_variant: this.code_for_all_variant,
            product_code: this.product_code,
            category_id: this.Category || 1,
            brand_id: this.brandname,
            warranty_type: this.warranty_type,
            warranty: this.prodcutwarranty,
            product_seller_id: this.shop_id,
            model: this.model,
            min_selling_price: this.minimumprice,
            youtube_video_url: this.YouTubevideo,
            description: strippedContent=== "" ? "Demo" : strippedContent,
            cod_available: this.cod_available ? 0 : 1,
            product_featured: this.product_featured ? 0 : 1,
            product_approved: this.product_approved ? 0 : 1,
            product_specification: specificatios,
            product_options: product_options
        };
    
        // Conditionally append product_files based on the presence of "default" check
        if (!hasDefaultCheck) {
            requestObject.product_files = selectedValues;
        } else {
            requestObject.product_files = filteredSelectedValues;
        }
    
        // Make the API call with the constructed request object
        putMethod("/api/v1/admin/products/edit", requestObject, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadProductCatalog = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.formErrors?.variantImage || resp?.data?.formErrors?.defaultImage || resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadProductCatalog = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getProductCatalogList();
                        this.saved = true;
                    })
                    return;
                }
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.formErrors?.variantImage || error?.response?.data?.formErrors?.defaultImage || error?.response?.data?.message);
                });
                return;
            });
    };
    









    addProductImage = async (image) => {
        runInAction(() => {
            this.errLoadProductCatalog = "";
        });

        const formData = new FormData();
        if (image) {
            // const Logobase64String = image?.split(',')[1];
            // const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('logo_image', image);
        }

        // formData.append('youtube_video_url', this.YouTubevideo);





        try {
            const resp = await postMethodData("/api/v1/admin/products/upload-image", formData, this.Token);

            if (resp?.data?.status === false) {
                runInAction(() => {
                    // ... (unchanged code)
                });
            } else if (resp?.data?.status === true) {
                const imageName = resp?.data?.data?.logo_image.uploaded_file;
                runInAction(() => {
                    // ... (unchanged code)
                    this.setuploaded_file(imageName);
                });
                return imageName; // Returning image name
            }
        } catch (error) {
            runInAction(() => {
                // ... (unchanged code)
            });
        }
    };

    //Active and inactive 
    statusChange = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;

            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products/update-status`, {

            "ProductCatalogId": JSON.stringify(this.ProductCatalogid),
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getProductCatalogList();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allProductCatalog) => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;

            this.errLoadProductCatalog = "";
        });

        postMethodData(`/api/v1/admin/ProductCatalog/toggle-bulk-status`, {

            "branchId": JSON.stringify(allProductCatalog),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getProductCatalogList();

                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    //Delete image
    deleteImagefunction = (img_name,isVariantImage) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
        });
        
        postMethodData(`/api/v1/admin/products/delete-image`, {

            "img_name": img_name[0],
            "is_variant_image": isVariantImage || 1


        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getProductCatalogList();

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products/delete`, {

            "record_id": JSON.stringify(this.ProductCatalogid),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getFilterProductCatalog();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allProductCatalog) => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;

            this.errLoadProductCatalog = "";
        });

        postMethodData(`/api/v1/admin/ProductCatalog/multi-delete`, {

            "ProductCatalogId": JSON.stringify(allProductCatalog),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getFilterProductCatalog();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    //Delete sepecification
    deleteSpecification = (id , category_id) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products/delete-prod-spec`, {

            "record_id": category_id ,
            "prod_spec_id": id

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getProductCatalogList();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    //get ProductCatalog by id
    getProductCatalogform = async (id) => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;
            this.errLoadProductCatalog = "";
        });

        try {
            const resp = await postMethodData(`/api/v1/admin/products/form`, {
                "record_id": id,
            }, this.Token);

            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
                return;
            }

            if (resp?.data?.status === true) {
                const defaultImagePromises = resp.data?.data?.defaultImageDetail?.map(async item => {
                    if (item?.image) {
                        try {
                            const response = await fetch(`http://192.168.1.38:5000/uploads/product/${item?.image}`);
                            const blob = await response.blob();
                            const base64data = await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = () => resolve(reader?.result);
                                reader.onerror = reject;
                            });

                            return {
                                uid: item?.id,
                                thumbUrl: base64data,
                                status: "done"
                            };
                        } catch (error) {
                            console.error('Error fetching image or converting to base64:', error);
                            // Handle the error here instead of throwing it
                            // For example, you can return a default object or null
                            return null;
                        }
                    }
                });

                const optionImagePromises = resp?.data?.data?.productOptionDetail?.map(async item => {
                    if (item?.image_data?.length > 0) {
                        try {
                            const response = await fetch(`http://192.168.1.38:5000/uploads/product/${item?.image_data[0]?.image}`);
                            const blob = await response.blob();
                            const base64data = await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = () => resolve(reader.result);
                                reader.onerror = reject;
                            });
                
                            return {
                                uid: item?.id,
                                thumbUrl: base64data,
                                status: "done"
                            };
                        } catch (error) {
                            console.error('Error fetching image or converting to base64:', error);
                            // Handle the error here instead of throwing it
                            // For example, you can return a default object or null
                            return null;
                        }
                    }
                });
                
                let optionImageDetail = [];
             console.log("Option%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",optionImagePromises);
             const settledOptionImageDetailPromises = await Promise.allSettled(optionImagePromises);
for (const promise of settledOptionImageDetailPromises) {
    console.log("promise.status ",promise );
    if (promise.value ) {
        console.log("HOw many timesssssssss");
        optionImageDetail.push(promise.value);
    }
}
    
                const [defaultImageDetail] = await Promise?.all([Promise.all(defaultImagePromises)]);

                // Merge option images with default images
                const mergedImages = [...defaultImageDetail, ...optionImageDetail];

                // Construct new objects based on productOptionDetail array
                const newObjects = resp?.data?.data?.productOptionDetail?.map((item, index) => {
                    const image = mergedImages.find(img => img.uid === item.id);
                    console.log("optionDetail", item);
                    return {
                        option_value_id: item.option_value_id,
                        option_id: null,
                        check:"default",
                        is_variant_image: "1",
                        uploaded_file: image ? [item.image_data[0].image] : [],
                        price_type: item.price_type,
                        price: item.price,
                        quantity: item.quantity,
                        type: item.option_type,
                    };
                });

                // If defaultImageDetail exists, create objects with specific properties
                let defaultObjects = [];
                if (resp.data.data.defaultImageDetail.length > 0) {
                    defaultObjects = resp.data?.data?.defaultImageDetail?.map(item => ({
                        option_value_id: item.option_value_id,
                        option_id: null,
                        is_variant_image: "0",
                        check:"default",
                        uploaded_file: [item.image],
                        price_type: null,
                        price: null,
                        quantity: null,
                        type: null,
                    }));
                }
                const optionObject = resp?.data?.data?.productOptionDetail?.map((item, index) => {
                    console.log("optionDetail", item);
                    return {
                        option_id: null,
                        data: [{
                            option_value_id: item.option_value_id,
                            is_variant_image: "1",
                            pricetype: item.price_type,
                            price: item.price,
                            quantity: item.quantity,
                            type: item.option_type,
                        }]


                    };
                });
                const spec = resp?.data?.data?.productSpecificationDetail?.map((item, index) => {
                    console.log("optionDetail", item);
                    return {
                    
                            id: item.spec_id,
                            name:  item.spec_name,
                            value: item.spec_value,
                            group: item.spec_group
                        


                    };
                });
             
                // { option_id: '', data: [{ option_value_id: [], price: '', quantity: '', incdec: '', }] }
                // Append defaultObjects to newObjects
                const allObjects = [...defaultObjects, ...newObjects];
               

                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;
                    this.productDetail = resp.data.data.productDetail;
                    this.productSpecificationDetail = resp.data.data.productSpecificationDetail;
                    this.productOptionDetail = resp.data.data.productOptionDetail;
                    this.code_for_all_variant = resp.data.data.productDetail.code_for_all_variant;
                    this.cod_available = resp.data.data.productDetail.cod_available;
                    this.product_featured = resp.data.data.productDetail.is_featured;
                    this.product_approved = resp.data.data.productDetail.is_verified;
                    this.is_active = resp.data.data.productDetail.is_active;
                    this.productIdentifier = resp.data.data.productDetail.product_code;
                    this.productName = resp.data.data.productDetail.product_name;
                    this.model = resp.data.data.productDetail.model;
                    this.minimumprice = resp.data.data.productDetail.min_selling_price;
                    this.prodcutwarranty = resp.data.data.productDetail?.warranty;
                    this.warranty_type = resp.data.data.productDetail?.warranty_type;
                    this.YouTubevideo = resp.data.data.productDetail.youtube_url;
                    this.content = resp.data.data.productDetail.description;
                    this.product_code = resp.data.data.productDetail.product_code;
                    this.brandname = resp.data.data.productDetail.brand_id;
                    this.optionDetail = allObjects;
                    this.defaultImageDetail = mergedImages;
                    this.optionslist = optionObject;
                    this.specificationDetail = spec;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.shop_id =  resp.data.data.productDetail.product_seller_id;
                    this.Category =  resp.data.data.productDetail?.category_id;
                });
                return;
            }

            runInAction(() => {
                this.ProductCatalogLoader = false;
                this.ProductCatalogpopup = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });
        } catch (error) {
            runInAction(() => {
                if (!error.response) {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;
                    this.errLoadProductCatalog = error?.message;
                    toast.error(error?.message);
                }
                toast.error(error?.response?.data?.message);
                this.ProductCatalogLoader = false;
                this.ProductCatalogpopup = false;
                this.errLoadProductCatalog = error?.response?.data?.message;
            });
        }
    };






    // get ProductOptions list
    getProductOptionsList = () => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductOptions = [];
        });
        getMethodData(`/api/v1/admin/options/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductOptions = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };


    // get ProductOptions list
    getProductValuesList = (id) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductValues = [];
        });
        getMethodData(`/api/v1/admin/options-values/get-list/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.setproductValueid(resp?.data?.data)
                        this.ProductValues = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //update method
    updateProductCatalog = (id) => {
        runInAction(() => {
            this.addloadProductCatalog = true;
            this.errLoadProductCatalog = "";
        });
        const formData = new FormData();
        if (this.ProductCatalogFormDetails.logo_image) {
            const Logobase64String = this.ProductCatalogFormDetails.logo_image?.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('logo_image', logoFile);
        }


        if (this.ProductCatalogFormDetails.banner_image) {
            const Bannerbase64String = this.ProductCatalogFormDetails.banner_image?.split(',')[1];
            const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
            formData.append('banner_image', BannerFile);
        }


        if (this.crop.dimensions?.height) {
            formData.append('logo_height', this.crop.dimensions?.height);
        }


        if (this.crop.dimensions?.width) {
            formData.append('logo_width', this.crop.dimensions?.width);
        }

        if (this.BannerDimiensions?.dimensions?.height) {
            formData.append('banner_height', this.BannerDimiensions?.dimensions?.height);
        }


        if (this.BannerDimiensions?.dimensions?.width) {
            formData.append('banner_width', this.BannerDimiensions?.dimensions?.width);
        }

        formData.append('ProductCatalogId', id);
        formData.append('name', this.ProductCatalogFormDetails.name);
        formData.append('seo_url', this.ProductCatalogFormDetails.seo_url);
        formData.append('is_active', this.ProductCatalogFormDetails.is_active);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/ProductCatalog/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getProductCatalogList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };
}




export const ProductCatalogStoreContext = createContext(new ProductCatalogStore());
export const useProductCatalogStore = () => useContext(ProductCatalogStoreContext);
