import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_URL;

// get and post method api admin

export const postLogin = async (API_URL,Data) => {
    try {
        const response = await axios.post(`${BASE_URL}${API_URL}`,Data);
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}

export const getMethodData = async (API_URL,Token) => {
    try {
        const response = await axios.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${Token}` } });
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}

export const postMethodData = async (API_URL,data,Token) => {
    try {
        const response = await axios.post(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } });
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}

export const patchMethodData = async (API_URL,data,Token) => {
    try {
        const response = await axios.patch(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } });
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}


export const getSubmitData = async (API_URL,TokenGet) => {
    try {
        const response = await axios.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${TokenGet}` } });
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}
export const putMethod = async (API_URL,data,TokenGet) => {
    try {
        const response = await axios.put(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${TokenGet}` } });
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}
export const delMethod = async (API_URL,TokenGet, reason) => {
    try {
        const response = await axios.delete(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${TokenGet}` ,reason:reason} });
        return response;
      } catch (error) {
        // Handle the error here
      //   console.log('An error occurred while trying to post login:', error);
        throw error; // Rethrow the error so that the caller can handle it
    }
}