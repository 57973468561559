import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class AUserListStore {

    Token = null;
    AUserLists = [];
    filterSearch = "";
    PaginationPage = "1";
    alert="";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadAminUsers = "";
    LoadAUserLists = false;
    AUserListLoader = false;
    delateLoader = false;
    nolist = false;
    AUserListpopup = false;
    deletepopup = false;
    name = "";
    shopname = "";
    status = "1";
    statesid = "";
    user_id = [];
    seoUrl = "";
    statuspopup = false;
    addbrandname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allbrand = [];
    alldeactivate = 'false';
    checkeddd = '';
    password = "";
    errPassword = "";
    confirmresetpas = "";
    mobileno = "";
    email = "";
    alert = "false";
    passwordpopup = false;
    passwordLoading = false;
    AdminPermissions=[];
    adminpermissions={};
    AUserListFormDetails = {
        "full_name": "",
        "shop_name" : "",
        "seo_url" : "",
        "mobile": "",
        "email": "",
        "system_generated_alert": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addbrandname: observable,
            status: observable,
            errorMessages: observable,
            setaddbrandname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getAUserListList();

            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.AdminUsereditloading) {
            this.getAUserListform(isloading.id);
            return
        }
        if (isloading.AdminPermissions) {
            this.getAdminPermissions(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = (id) => {
        this.filterSearch = ""
        this.getFilterAUserList(id);
        // this.getAdminPermissions(id);
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };



    getid = (text) => {
        this.user_id.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBrand = (text) => {
        this.allbrand = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getbrandname = (text) => {
        this.brandname = text;
    };
    setemail = (text) => {
        this.email = text;
    };
    setname = (text) => {
        this.name = text;
    };
    setShopname = (text) => {
        this.shopname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setAUserListspopup = (e) => {
        this.AUserListpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getmobileno = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.mobileno = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    // get password value
    passwordChanged = (text) => {
        this.password = text
    };
   
    Confirmpassw = (text) => {
        this.confirmresetpas = text
    };
    setalert = (text) => {
        this.alert = !this.alert
    };
    setpasswordpopup = () => {
        this.passwordpopup = !this.passwordpopup
        this.errPassword = ""
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
    }

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setAUserListFormDetails = (AUserListDetail) => {
        this.AUserListFormDetails = {
            ...this.AUserListFormDetails,
            full_name: AUserListDetail.full_name || "", // Use default value if name is undefined
            mobile: AUserListDetail.mobile || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.AUserListFormDetails.logo_image) ? this.logofile.length == 0 ? AUserListDetail.logo_image    : this.AUserListFormDetails.logo_image  : this.logofile.length !== 0 ? this.AUserListFormDetails.logo_image :  AUserListDetail.logo_image   || "",
            // banner_image: this.isBase64(AUserListDetail.banner_image) ? this.AUserListFormDetails.banner_image : AUserListDetail.banner_image || "",
            email: AUserListDetail.email || "",
            shop_name: AUserListDetail.shop_name || "",
            seo_url : AUserListDetail.seo_url || "",
            system_generated_alert: AUserListDetail.system_generated_alert || "",
            is_active: AUserListDetail.is_active
        };
    };
    setadminpermissions = (permissions) => {
        this.adminpermissions = {
          ...this.adminpermissions,
          ...permissions,
        };
        console.log(JSON.stringify(this.adminpermissions));
      };
    // setAUserListFormDetails = (text) => {
    //     this.AUserListFormDetails = text;
    //     console.log("setAUserListFormDetails", this.AUserListFormDetails.brandname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get brand list
    getAUserListList = () => {
        runInAction(() => {
            this.AUserListLoader = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
            this.AUserLists = [];
        });
        getMethodData(`/api/v1/admin/seller/get-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.errLoadAminUsers = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AUserLists = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadAUserLists = false;
                    this.errLoadAminUsers = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.AUserListLoader = false;
                    if (!error.response) {
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter Admin User

    getFilterAUserList = () => {
        runInAction(() => {
            this.LoadAUserLists = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/seller/get-list?page=${ this.PaginationPage  }`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AUserLists = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // filter User Permissions

    getFilterUserPermissions = (id) => {
        runInAction(() => {
            this.LoadAUserLists = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
            this.getAUserListList = [];
        });
        postMethodData(`/api/v1/admin/admin-permissions/search`, {
            keyword: this.filterSearch,
            recordId:id

        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AdminPermissions = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new User
    addUser = () => {
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.addloadAUserListsLoadAUserLists = true;
            this.errLoadAminUsers = "";
        });
        const formData = new FormData();

        formData.append('seller_name', this.name);
        formData.append('shop_name', this.shopname);
        formData.append('seo_url', `${SEO_URL}${this.seoUrl}`);
        // formData.append('seo_url', this.seoUrl);
        formData.append('mobile', this.mobileno);
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('status', this.status);
        formData.append('alert', this.alert ? 0 : 1);

        postMethodData("/api/v1/admin/seller/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadAUserListsLoadAUserLists = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadAUserListsLoadAUserLists = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterAUserList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadAUserListsLoadAUserLists = false;
                this.errLoadAminUsers = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadAUserListsLoadAUserLists = false;
                    if (!error.response) {
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = (id) => {
        runInAction(() => {
            this.AUserListLoader = true;
            this.AUserListpopup = true;

            this.errLoadAminUsers = "";
        });
        console.log("statussssssssssssssss", this.user_id);
        postMethodData(`/api/v1/admin/seller/update-status`, {
            "status": this.status,
            "record_id": this.user_id
        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.user_id = [];
                        this.errLoadAminUsers = resp?.data?.message;
                        this.getAUserListList();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;
                    this.user_id = [];
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allbrand) => {
        runInAction(() => {
            this.AUserListLoader = true;
            this.AUserListpopup = true;

            this.errLoadAminUsers = "";
        });

        postMethodData(`/api/v1/admin/admin-users/update-status`, {


            "recordId": JSON.stringify(allbrand),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterAUserList();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/seller/delete`, {

            "record_id": this.user_id,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadAUserLists = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadAUserLists = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.user_id = [];
                    this.errLoadAminUsers = resp?.data?.message;
                    this.getAUserListList();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadAUserLists = false;
                this.errLoadAminUsers = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadAUserLists = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.user_id = [];
                    this.LoadAUserLists = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allbrand) => {
        runInAction(() => {
            this.AUserListLoader = true;
            this.AUserListpopup = true;

            this.errLoadAminUsers = "";
        });

        postMethodData(`/api/v1/admin/brand/multi-delete`, {

            "user_id": JSON.stringify(allbrand),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterAUserList();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Brand by id
    getAUserListform = (id) => {
        runInAction(() => {
            this.AUserListLoader = true;
            this.AUserListpopup = true;

            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/seller/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setAUserListFormDetails(resp.data.data);
                        // this.alert = resp.data.data.userDetail.system_generated_alert;
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateEditAdmin = (id) => {
        runInAction(() => {
            this.addloadAUserListsLoadAUserLists = true;
            this.errLoadAminUsers = "";
        });

        const formData = new FormData();
        console.log(this.AUserListFormDetails.system_generated_alert);

        formData.append('seller_name', this.AUserListFormDetails.full_name);
        formData.append('mobile', this.AUserListFormDetails.mobile);
        formData.append('seo_url', this.AUserListFormDetails.seo_url);
        formData.append('shop_name', this.AUserListFormDetails.shop_name);
        formData.append('record_id', id);
        formData.append('email', this.AUserListFormDetails.email);
        // formData.append('alert', this.AUserListFormDetails.system_generated_alert || this.alert);
        formData.append('status', this.AUserListFormDetails.is_active);




        postMethodData(`/api/v1/admin/seller/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getFilterAUserList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }

                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.AUserListLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //Admin Permissions List 

    getAdminPermissions = (id) => {
        runInAction(() => {
            this.AUserListLoader = true;
            this.AUserListpopup = true;

            this.errLoadAminUsers = "";
        });
        getMethodData(`/api/v1/admin/admin-permissions/list/${id}`,
     this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminPermissions = resp?.data?.data;
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.AUserListpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AUserListLoader = false;
                    this.AUserListpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //update password
    updateUserPass = (id) => {
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.passwordLoading = true;
            this.errLoadAminUsers = "";
        });

        const formData = new FormData();
        formData.append('password', this.password);
        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('recordId', id);

        postMethodData(`/api/v1/admin/admin-users/update-password`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setpasswordpopup();
                        toast.success(resp.data.message);
                        this.getFilterAUserList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AUserListLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AUserListLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //Update User Permissions
    updateUserPermissions = async(id , permission_value) => {
       await postMethodData(`/api/v1/admin/admin-permissions/update`,
            {
                permissionList : this.adminpermissions ,
                recordId:id
            },
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AUserListLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        toast.success(resp.data.message);
                        this.getAdminPermissions(id);
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.AUserListLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {

                        this.AUserListLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                console.log(error);

                    this.AUserListLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
}





export const AUserListStoreContext = createContext(new AUserListStore());
export const useAUserListdStore = () => useContext(AUserListStoreContext);
