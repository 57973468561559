import React, { useState } from "react";
import { RxEyeClosed, RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdBlock, MdEdit, MdLocationCity, MdLockPerson, MdOutlineDelete, MdOutlineLockPerson, MdPassword, MdPerson, MdPerson3, MdRemoveRedEye } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
// import { BrandStore, useBrandStore, BrandStoreContext } from "./store/BrandStore";
import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { useAuthStore } from "../Auth/store/Auth";
import Filter from "./AdminPermissionsFilter/Filter";
import { useEffect } from "react";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

const AdminPermissions = () => {
    const { id } = useParams();

    const store = new AdminUseStore({
        loadeditcitybol: false,
        AdminPermissions: true,
        id: id
    });
    return (
        <AdminUserStoreContext.Provider value={store}>
            <Screen />
        </AdminUserStoreContext.Provider>
    )
}
export default AdminPermissions;


const Screen = observer(() => {
    const {
        AdminUsers,
        loadcity,
        nolist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        AdminUserLoader,
        // setallAdminUsers,
        // allAdminUsers,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getBrandList,
        getFilterAdminUser,
        TotalPages,
        setadminuserspopup,
        adminUserpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        setpasswordpopup,
        passwordpopup,
        passwordChanged,
        Confirmpassw,
        updateUserPass,
        setadminpermissions,
        updateUserPermissions,
        adminpermissions,
        passwordLoading,
        AdminPermissions,
        getToken
    } = useAdminUsedStore();

    const { adminAuthApproved } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [radiovalue, setradiovalue] = useState();

    const [allAdminUsers, setallAdminUsers] = useState([])
    const { id } = useParams();

    // const [checkeddd, setchecked] = useState(false)
    const { name } = useParams();

    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected);
        getFilterAdminUser();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const AdminUser = AdminUsers?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    console.log("AdminPermissions", adminpermissions);
    const checkAllCheckboxes = () => {
        // setallAdminUsers([]);
        // Get all checkboxes with the same name attribute
        var checkboxes = document.getElementsByName('myCheckbox');

        if (checkeddd) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            setallAdminUsers([]);
        } else {
            var emails = [];
            var commaSeparatedEmails = [];
            // var emailArray = emailString.split(",").map((email) => email.trim());
            // Loop through each checkbox and check it
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                var emailValues = checkboxes[i].value.split(',');
                if (emailValues.length > 1) {
                    commaSeparatedEmails.push(...emailValues);
                } else {
                    var emailValue = emailValues[0].trim();
                    if (emailValue !== '' && !emails.includes(emailValue)) {
                        emails.push(emailValue);
                    }

                }
            }
            setallAdminUsers((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
        }

    }
    const isallcheck = (e) => {
        var checkboxes = document.getElementsByName('myCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            var updatedEmails = Array.from(checkboxes)
                .filter((checkbox) => checkbox.checked)
                .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
            setallAdminUsers(updatedEmails);
            if (!checkboxes[i].checked) {
                setchecked(false)
                return
            }
            else {
                setchecked(true)
            }

        }
    }

    console.log("updatedEmails===============>>>>>>>>>>>", allAdminUsers);

    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" opacity-80 text-sm">Home / Admin Users / permissions / <span className="font-extrabold">{name}</span> </p>
                </div>

            </div>
            <Filter id={id} />

            <div className="flex justify-between items-center py-2">
                <div className="ml-3">
                    <label className=" font-extrabold">
                        <input className="mr-2" checked={checkeddd} type="checkbox" name="selectall"
                            onClick={() => { checkAllCheckboxes(); setchecked(!checkeddd) }}


                        />
                        Select All
                    </label>
                </div>
                <div className="space-x-3 flex justify-center">
                    {/* { allemail.length !== 0 && */}
                    {/* <label
                        htmlFor="emailpopup"
                        className={`btn btn-md ${allAdminUsers.length == 0 && "opacity-50"}  bg-[#000] text-color_theme text-sm hover:bg-[#000]`}
                    // onClick={() => setPopupemail(true)}
                    >
                        All Deactivate
                    </label > */}
                    <button
                        onClick={() => {
                            // Create an object to hold the updated admin permissions
                            const updatedAdminPermissions = {};

                            // Iterate through each admin user and set their permissions to 0
                            allAdminUsers.forEach((user) => {
                                updatedAdminPermissions[user] = 0;
                            });

                            // Update the admin permissions state with the new values
                            setadminpermissions({
                                ...adminpermissions,
                                ...updatedAdminPermissions,
                            });

                            // Call the function to update user permissions with the new admin permissions
                            updateUserPermissions(id, adminpermissions);
                        }}
                        disabled={allAdminUsers.length === 0}
                        className={`${allAdminUsers.length === 0
                            ? "opacity-50"
                            : "hover:text-[white] hover:bg-color_theme"
                            } p-3 bg-slate-400 rounded-2xl text-gray-900 relative justify-center text-center inline-flex cursor-pointer`}
                    >
                        <div className="flex text-center justify-center">
                            <span className="text-center">
                                <MdBlock />
                            </span>
                            <span className="ms-1 text-sm font-medium dark:text-gray-300">
                                None
                            </span>
                        </div>
                    </button>
                    <button onClick={() => {
                        // Create an object to hold the updated admin permissions
                        const updatedAdminPermissions = {};

                        // Iterate through each admin user and set their permissions to 0
                        allAdminUsers.forEach((user) => {
                            updatedAdminPermissions[user] = 2;
                        });

                        // Update the admin permissions state with the new values
                        setadminpermissions({
                            ...adminpermissions,
                            ...updatedAdminPermissions,
                        });

                        // Call the function to update user permissions with the new admin permissions
                        updateUserPermissions(id, adminpermissions);
                    }}
                        disabled={allAdminUsers.length === 0}
                        class={` ${allAdminUsers.length == 0 ? "opacity-50" : "hover:text-[white] hover:bg-color_theme "} p-3 bg-slate-400 rounded-2xl  text-gray-900  relative  justify-center text-center   inline-flex   cursor-pointer`}>

                        {/* <div className="flex" onClick={() =>{ BulkDelete(allAdminUsers)}}  > */}
                        <div className="flex text-center justify-center " >
                            <span className="text-center">
                                <MdRemoveRedEye />

                            </span>
                            <span class="ms-1 text-sm font-medium   dark:text-gray-300">
                                Read
                            </span>
                        </div>

                    </button>
                    <button onClick={() => {
                        // Create an object to hold the updated admin permissions
                        const updatedAdminPermissions = {};

                        // Iterate through each admin user and set their permissions to 0
                        allAdminUsers.forEach((user) => {
                            updatedAdminPermissions[user] = 1;
                        });

                        // Update the admin permissions state with the new values
                        setadminpermissions({
                            ...adminpermissions,
                            ...updatedAdminPermissions,
                        });

                        // Call the function to update user permissions with the new admin permissions
                        updateUserPermissions(id, adminpermissions);
                    }}
                        disabled={allAdminUsers.length === 0}
                        class={` ${allAdminUsers.length == 0 ? "opacity-50" : "hover:text-[white] hover:bg-color_theme "} p-3 bg-slate-400 rounded-2xl  text-gray-900  relative  justify-center text-center   inline-flex   cursor-pointer`}>


                        {/* <div className="flex" onClick={() =>{ BulkDelete(allAdminUsers)}}  > */}
                        <div className="flex text-center justify-center " >
                            <span className="text-center">
                                <MdEdit />

                            </span>
                            <span class="ms-1 text-sm font-medium   dark:text-gray-300">
                                Read and write
                            </span>
                        </div>

                    </button>

                    {/* 
                    <label
                        // htmlFor="emailpopup" 
                        className={`btn btn-md ${allAdminUsers.length == 0 && "opacity-50"}  bg-[#000] text-color_theme text-sm hover:bg-[#000]`}

                    // onClick={()=>setPopupemail(true)}
                    >
                        All Delete

                    </label > */}
                    {/* <button class={` btn relative ${allAdminUsers.length == 0 && "opacity-50"}  justify-center text-center   inline-flex   cursor-pointer`}> */}

                    {/* } */}


                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                <div className="flex justify-between">
                                    <th className="ml-6 text-[15px] bg-white">Module</th>
                                    <th className="text-[15px] bg-white">Permissions</th>
                                </div>

                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {AdminUserLoader &&
                                <>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                (AdminPermissions?.length === 0 ? AdminPermissions : AdminPermissions)?.map((data, index) => (

                                    <tr
                                        // key={index}
                                        className="capitalize flex justify-between">


                                        <td className="bg-white " style={{ "background-color": "white" }} >
                                            <div className="flex space-x-3">
                                                <input
                                                    // value={`${data?.players?.mate?.email} ? ${data?.players?.user?.email}`} 
                                                    value={data.db_column}

                                                    onChange={(e) => {
                                                        setchecked(false); isallcheck(e.target.value);
                                                    }}
                                                    type="checkbox"
                                                    name="myCheckbox"
                                                />
                                                <span>
                                                    {data?.user_column}
                                                </span>
                                            </div>
                                        </td>



                                        <td className="bg-white " style={{ "background-color": "white" }} >
                                            <div className="flex space-x-3">
                                                <div className="flex items-center space-x-1">
                                                    {/* <div className="flex  rounded-sm hover:bg-slate-200 cursor-pointer"> */}
                                                    {console.log(adminpermissions)}

                                                    <label
                                                        className="flex p-2  rounded-sm space-x-2 hover:bg-slate-200 cursor-pointer">
                                                        <input
                                                            id={data.id}
                                                            name={data.id}
                                                            defaultChecked={data.permission_value == 0}
                                                            value={data.permission_value}

                                                            onChange={(e) => {
                                                                setadminpermissions({
                                                                    ...adminpermissions,
                                                                    [data.db_column]: 0,
                                                                });
                                                                updateUserPermissions(id, adminpermissions)
                                                            }}
                                                            // checked={data.permission_value == 0}
                                                            className="w-[17px] h-[17px] appearance-none border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                                                            type="radio"
                                                        />
                                                        <span>None</span>
                                                    </label>
                                                    {/* </div> */}


                                                    <label

                                                        className="flex p-2  rounded-sm space-x-2 hover:bg-slate-200 cursor-pointer">

                                                        <input
                                                            id={data.id}
                                                            name={data.id}
                                                            defaultChecked={data.permission_value == 2}
                                                            value={data.permission_value}

                                                            onChange={(e) => {
                                                                setadminpermissions({
                                                                    ...adminpermissions,
                                                                    [data.db_column]: 2,
                                                                })
                                                                updateUserPermissions(id, adminpermissions)
                                                            }}
                                                            className="w-[17px] h-[17px] appearance-none border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                                                            type="radio"
                                                        />
                                                        <span>Read</span>
                                                    </label>


                                                    <label

                                                        className="flex p-2  rounded-sm space-x-2 hover:bg-slate-200 cursor-pointer">


                                                        <input
                                                            id={data.id}

                                                            name={data.id}
                                                            defaultChecked={data.permission_value == 1}
                                                            value={data.permission_value}
                                                            onChange={(e) => {
                                                                setadminpermissions({
                                                                    ...adminpermissions,
                                                                    [data.db_column]: 1,
                                                                })
                                                                updateUserPermissions(id, adminpermissions)
                                                            }}
                                                            // checked={data.permission_value == 1}
                                                            className="w-[17px] h-[17px] appearance-none border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                                                            type="radio"
                                                        />
                                                        <span>Read and write</span>

                                                    </label>
                                                </div>
                                            </div>


                                        </td>

                                    </tr>

                                ))
                            }


                        </tbody>
                    </table>


                </div>
            </div>
        </>
    );
});

