import { observer } from "mobx-react";
import React, { useEffect } from 'react'
import { Input } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useProfileStore,ProfileStoreContext,ProfileStore } from './store/Profilestore'
import { useAuthStore } from "../Auth/store/Auth";

// const Profile = observer (() => {
//     return (
//         <ProfileStoreContext.Provider
//           value={
//             new ProfileStore({
//               loadeditcitybol: false,
//               editloading: true,
//             //   id: id,
//             })
//           }
//         >
//           <Screen />
//         </ProfileStoreContext.Provider>
//       );
// })

// export default Profile

const Profile = observer (()=>{

    const {
        firstName,
        lastname,
        email,
        setAdmin_Profile_Details,
        getlastname,
        updateprofile,
        Admin_Profile_Details,
        addloadprofile
      } = useAuthStore();
      
    //   useEffect(() => {
    //     getprofileone()
    //   }, [])
      
console.log(Admin_Profile_Details);
  return (
   <>
      <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold">Profile</p>
                {/* <p className=" opacity-80 text-sm">Home / Profile</p> */}
            </div>
            <div>
                <Link to="../" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
            </div>
        </div>

        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {updateprofile();e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="fname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">First Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="fname" 
                        onChange={(e) => setAdmin_Profile_Details({
                            ...Admin_Profile_Details,
                            full_name: e.target.value,
                        })}
                        value={Admin_Profile_Details.full_name}
                        required
                        placeholder="First Name" 
                        id="fname" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="lname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Username<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="username" 
                        disabled
                        onChange={(e) => setAdmin_Profile_Details({
                            ...Admin_Profile_Details,
                            username: e.target.value,
                        })}
                        value={Admin_Profile_Details.username}
                        placeholder="User Name" 
                        id="username" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Email</label>
                        <Input 
                        name="email" 
                        disabled
                        value={Admin_Profile_Details.email}
                        placeholder="Email" 
                        id="email" 
                        type="email" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1"/>Cancel</Link>
                        {!addloadprofile ?
                         <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineCheck className="mr-1"/>Update</button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }
                        
                </div>
            </form>
        </div>
   </>
  )
})
export default Profile